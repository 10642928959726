/**
 * JW Player customizations.
 *
 * Put any player customizations into this JW player plugin.
 *
 * JW JavaScript API reference
 * https://developer.jwplayer.com/jwplayer/docs/jw8-javascript-api-reference#jwplayergetenvironment
 *
 * @param {object} player The JW Player object.
 */
function jwCustomizations(player) {
    player.on('ready', function () {
        /**
         * Get the container element.
         * https://developer.jwplayer.com/jwplayer/docs/archived-jw8-javascript-api-reference#jwplayergetcontainer
         */
        var container = player.getContainer();
        /**
         * Get the environment conditions.
         * https://developer.jwplayer.com/jwplayer/docs/jw8-javascript-api-reference#jwplayergetenvironment
         */
        var mobile = player.getEnvironment().OS.mobile;
        /**
         * Disables mobile floating player when this feature has not been enabled.
         *
         * Uses a negative comparison because using a positive comparison
         * means setting the player floating to true, i.e., `player.setFloating(true)`
         * which results in undesired behavior, i.e., the player floats immediately on page load.
         */
        if (mobile
            && typeof nypJwVars !== 'undefined' // eslint-disable-line no-undef
            && typeof nypJwVars.jwFloating !== 'undefined' // eslint-disable-line no-undef
            && nypJwVars.jwFloating !== 'enable' // eslint-disable-line no-undef
        ) {
            player.setFloating(false);
        }
        // Add a custom DOM data attribute for device context.
        container.dataset.deviceContext = mobile ? 'mobile' : 'desktop';
    });
}
var registerPlugin = window.jwplayerPluginJsonp || window.jwplayer().registerPlugin;
// Register the plugin.
registerPlugin('jwcustomizations.bundle.min', '8.0', jwCustomizations);
